.tooltip {
  align-items: flex-start;
  background: $gray-dark none repeat scroll 0 0 !important;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);
  border: 2px solid $gray-darkish;
  color: $gray-lightest;
  display: flex;
  padding: 0.5rem;
  max-width: 300px;
}

.tooltip--item-modal {
  border: none;

  @media (min-width: $screen-xs-min) {
    display: flex;
  }
}

.tooltip__buttons {
  display: flex;
  justify-content: flex-end;
}

.tooltip__icon {
  float: left;
  margin-right: 1rem;

  @media (min-width: $screen-xs-min) {
    float: none;
  }
}

.tooltip__desc {
  @supports (hyphens: auto) {
    hyphens: auto;
    text-align: justify;
  }
}

.tooltip__secondary-description {
  font-style: italic;

  .quality-name {
    font-weight: 500;
  }

  .unlock {
    display: block;
    margin-bottom: 0.5rem;
  }

  .unlock-separator {
    display: none;
  }
}

/* Misc stuff that only appears in tooltips */

.enhancements-description {
  color: $blue-lightish;
  display: block;
  font-style: normal;
}

.wordy-list {
  list-style: none;
  margin-top: 0.5em !important;
}

.wordy-list li {
  margin-left: 0.5rem;
}

.wordy-list .current {
  font-weight: 500;
}

.wordy-list li.current::after {
  content: " \f00c";
  font-family: 'FontAwesome';
  font-weight: normal;
}
