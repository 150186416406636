$gray-darkest: #0e0e0e;
$gray-darker: #2b2b2b;
$gray-darkish: #3e3e3e; // So many shades of dark gray!
$gray-dark: #1d1d1d;
$gray-mid: #666666;
$gray-lightish: #999999;
$gray-light: #bbbbbb;
$gray-lightest: #efefef;

$beige-dark: #cab588;
$beige-dark-rim: #eadecb;
$beige-mid: #bdb29e;
$beige-mid-light: #bdb6a8;
$beige-light: #ede3d2;
$beige-lightest: #fff4e1;
$green-dark: #62895f;
$green-light: #8cd186;

$blue-darker: #254245;
$blue-dark: #3f7277; // Link color on beige.
$blue-mid: #4c777b;
$blue-lightish: #7ebcc0;
$blue-light: #92d1d5;
$blue-lightest: #b9e1e4;

$yellow-dark: #C56F00;
$yellow: #fcac00;
$yellow-light: #ffda8a;

$red-dark: #861616;
$red-mid: #b11818;
$red-light: #ce1c1c;
$red-lightest: #eca1a1;

$green-mid: hsl(166, 100%, 32%);
$green-light: hsl(166, 100%, 44%);

$brown-dark: #282520; // Text color on beige.
$brown-mid: #756b5a; // Muted text on beige.
$brown-light: hsl(39, 14%, 50%); // Borders on beige.
$brown-lightest: #e8dac3; // Borders on beige.

$white: #efefef; // Silly, but keeps things consistent.
$black: #000000;
