$font-size-district: 16px;
$font-size-destination: calc(#{$font-size-district} * 0.75);
$font-size-landmark: calc(#{$font-size-district} * 0.7);

$font-weight-district: bold;
$font-weight-destination: 400;
$font-weight-landmark: 300;

.leaflet-tooltip,
.leaflet-tooltip-left,
.leaflet-tooltip-right {
  background: transparent;
  box-shadow: none;
  border: none;
  color: #fff;
  font-family: $font-family-serif;
  font-size: 16px;
}

.leaflet-tooltip-left::before,
.leaflet-tooltip-right::before {
  content: none;
}

.leaflet-tooltip-top::before {
  border-top-color: $gray-dark;
}

.leaflet-tooltip--fbg {
  align-items: center;
  border-radius: 0;
  transition: opacity 1s;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  text-shadow:
    0 0 3px #000,
    0 0 3px #000,
    0 0 3px #000,
    0 0 4px #000,
    0 0 4px #000,
    0 0 4px #000,
    0 0 4px #000;
  padding: 0;
  top: -16px;
  left: -16px;
}

.leaflet-tooltip--fbg-landmark {
  background-color: transparent;
}

.leaflet-tooltip--fbg-interactable {
  color: #fff;
}

.leaflet-tooltip--fbg__name {
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 0 $gray-darkest;
  cursor: pointer;
  font-size: 14px;
  background-color: rgba(156, 166, 169, 0.45);
  padding: 3px 5px;
}

.leaflet-tooltip--fbg__name--island {
  text-transform: capitalize;
}

.leaflet-tooltip--fbg__name--district {
  font-weight: $font-weight-district;
  font-size: $font-size-district;
  line-height: 1;
  text-transform: uppercase;
}

.leaflet-tooltip--fbg__name--district--non-interactive {
  background-color: transparent;
  border: none;
}

.leaflet-tooltip--fbg__name--destination {
  font-size: $font-size-destination;
  font-weight: $font-weight-destination;
}

.leaflet-tooltip--fbg__name--no-decoration {
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 0 0 $gray-darkest;
  cursor: pointer;
  font-size: $font-size-destination;
  background-color: transparent;
  padding: 3px 5px;
}

.leaflet-tooltip--fbg__name--landmark {
  background-color: transparent;
  border: none;
  cursor: grab;
  font-size: $font-size-landmark;
  font-weight: $font-weight-landmark;
}

.leaflet-tooltip--fbg__name--landmark-large {
  font-size: $font-size-district;
  font-weight: $font-weight-district;
  text-transform: uppercase;
}

.leaflet-tooltip--fbg__name--inaccessible {
  pointer-events: none;
}

.leaflet-tooltip--fbg__name--visitable-island {
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.leaflet-tooltip--fbg__name--large {
  transform: scale(1.1);
}

.leaflet-tooltip--fbg__name--huge {
  transform: scale(1.4);
}
