/**
 * Items.
 *
 * A horribly generic name for a generic component!
 *
 * An .icon on the left, some text and stuff on the right. eg:
 *
 * <div class="item">
 *	<div class="icon icon--inventory icon--hat"></div>
 *	<div class="item__desc">
 *		<span class="item__name">Hat</span>
 *    <span class="item__price">12.80</span>
 *  </div>
 * </div>
 *
 * Could have other stuff in the item__desc:
 *
 * <div class="item">
 *	<div class="icon icon--quality icon--dangerous"></div>
 *	<div class="item__desc">
 *		<span class="item__name">Watchful</span>
 *		<span class="item__value">37</span>
 *		<span class="item__adjust">+2</span>
 *		<span class="progress">
 *			<span class="progress__bar" style="width:60%"></span>
 *		</span>
 *	</div>
 * </div>
 *
 * Optionally, surround a bunch of .item's in a .items element.
 *
 * See components/_icon.scss for stuff specific to .icon's.
 * See components/_progress.scss for stuff specific to .progress bars.
 */

/****************************************************************************
 * ITEM STRUCTURE.
 * Base styles that may be changed by surrounding the .item's in a .items
 * element with an extra .items--* class.
 *
 * Puts the icon on the left, with the description on the right.
 * Plus default colors and font styles.
 */

.item {
  align-items: flex-start;
  font-family: $font-family-serif;
  display: flex;
  position: relative;

  &.extra-padding {
    padding-bottom: 0.5em;
  }

  .icon {
    margin-right: 10px;
  }
}

.item__desc {
  flex: 1;

  p {
    margin-top: 0.5em;
  }
}

.item__name {
  font-weight: $font-weight-bold;
  word-break: break-word;
}

.item__name--has-expansion {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

#tooltip .item {
  font-family: $font-family-sans-serif;
}

/****************************************************************************
 * Optional things within .item__desc - numbers.
 */

// The number after the text.
.item__value {
  color: $beige-mid;
  white-space: nowrap;

  .bastard & {
    color: $brown-mid;
  }

  // Special cases:
  .item--action &,
  .item--fate & {
    color: $yellow;
  }
}

// The number after the value.
.item__adjust {
  color: $blue-light;

  .bastard & {
    color: $blue-mid;
  }
}

/****************************************************************************
 * .items
 * Optionally surrounding individual .item's.
 *
 * Add the .items class plus one of the extra layout styles.
 */

// Base style.
.items {
  list-style: none;
}

/**
 * Additional class to make it look nice vertically.
 */

.items--list .item {
  padding-top: 10px;
	padding-bottom: 10px;
}

.items--list .item--no-padding {
	padding: 0;
}

/**
 * Additional class to make it horizontal.
 */
.items--inline {
  .item {
    display: inline-block;
    position: relative;
    margin: 0 5px 1em;

    .icon {
      margin-right: 0;
    }
  }

  .item__desc {
    width: auto;
  }

  .item__name {
    @include visually-hidden;
  }

  .item__value,
  .item__adjust {
    position: absolute;
    top: 28px;
    left: 53%;

    @include bubble($beige-light, $brown-dark);
  }

  .item__adjust {
    left: auto;
    right: 53%;
    background: $brown-mid;
  }

  .progress-bar {
    display: none;
  }
}

/****************************************************************************
 * Extra styles to change appearance (not layout).
 */

/**
 * Additional class to add a border around each icon.
 */
.items--emphasise {
  .icon {
    // Add pseudo element that has a blue further border.
    @include extra-border(after, -3px, 4px, #81bc4e);
  }

  .icon:hover {
    box-shadow: 0 0 8px 8px #81bc4e;
    cursor: pointer;
  }
}

.items--blocked {
  .icon {
    // Add pseudo element that has a blue further border.
    @include extra-border(after, -2px, 4px, $red-light);
  }

  .icon:hover {
    cursor: pointer;
    transform: none;
  }
}

/**
 * Additional class for the currencies.
 * Put lines between them.
 */
.items--currencies {
  border-bottom: 1px solid $black;

  .item {
    border-bottom: 1px solid $gray-darker;
    border-top: 1px solid $black;

    &:first-child {
      border-top: none;
    }
  }

  .item__name {
    display: block;
  }
}

.invert {
  -webkit-filter: invert(100%);
  filter: invert(100%);
  color: #424d61;
}
