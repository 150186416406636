/**
 * Will be compiled to become main.css.
 *
 * Currently includes ALL of the other .scss files.
 */

@import "variables/grid";

@import "variables/colours";
@import "variables/breakpoints";
@import "variables/container-sizes";
@import "variables/fonts";
@import "variables/map";
@import "variables/paths";
@import "variables/z-indices";
@import "variables";
// Should be first.

@import "animations/main";

@import "mixins/bubble";
@import "mixins/buttons";
@import "mixins/clearfix";
@import "mixins/extra-border";
@import "mixins/image";
@import "mixins/tab-focus";
@import "mixins/vendor-prefixes";
@import "mixins/visually-hidden";

@import "utils/cursors";
@import "utils/flex";
@import "utils/fx";
@import "utils/misc";
@import "utils/visibility";

@import "vendor/bootstrap/mixins/grid-framework";
@import "vendor/bootstrap/mixins/grid";
@import "vendor/font-awesome/font-awesome.scss";

@import "base/forms";
@import "base/reset";
@import "base/body";
@import "base/iconography";
@import "base/typography";

/* Components that may get overridden so need to get pulled earlier in source order */
@import "components/media";
@import "components/buttons";

/* More specific block-level components */
@import "components/account";
@import "components/account-link-reminder";
@import "components/act";
@import "components/action-candles";
@import "components/action-count-modal";
@import "components/agents";
@import "components/available-item-list";
@import "components/avatars";
@import "components/banner";
@import "components/braintree-dropin";
@import "components/branches";
@import "components/button";
@import "components/buttonlet";
@import "components/cameo";
@import "components/card";
@import "components/category-jumper";
@import "components/create-character";
@import "components/challenge";
@import "components/challenges";
@import "components/container";
@import "components/deck";
@import "components/disclosure";
@import "components/display-quality";
@import "components/effect-item-list";
@import "components/equipment-group";
@import "components/equipment-group-list";
@import "components/equipment-slot";
@import "components/equipped-item";
@import "components/error-boundary";
@import "components/exchange-ui";
@import "components/fallback-map";
@import "components/fan-favourite";
@import "components/fate-card";
@import "components/fate-header";
@import "components/footer";
@import "components/footer-content";
@import "components/gate-storylet";
@import "components/general-container";
@import "components/hand";
@import "components/header";
@import "components/icon";
@import "components/ineligible-contacts";
@import "components/inner-tabs";
@import "components/interactive-marker";
@import "components/inventory-group";
@import "components/item";
@import "components/item-search";
@import "components/journal-entries";
@import "components/journal-date-picker";
@import "components/leaflet";
@import "components/leaflet-container";
@import "components/leaflet-control";
@import "components/leaflet-interactive";
@import "components/leaflet-tooltip";
@import "components/list";
@import "components/loading";
@import "components/lodgings-subarea";
@import "components/login";
@import "components/map";
@import "components/map-limbo";
@import "components/menu-item";
@import "components/message";
@import "components/messages";
@import "components/metablurb";
@import "components/modals";
@import "components/myself";
@import "components/nav";
@import "components/news";
@import "components/outfit-controls";
@import "components/outfit-select";
@import "components/outfit-selector";
@import "components/opportunity-cards";
@import "components/pages";
@import "components/payment";
@import "components/plans";
@import "components/possessions";
@import "components/possessions-lodgings";
@import "components/purchase-outfit-slot-modal";
@import "components/purchase-outfit-success-form";
/* */
@import "components/premium-sub-purchase-card";
@import "components/privacy-policy";
@import "components/profile";
/* */
@import "components/profile-card";
@import "components/progress";
@import "components/purchase";
/* */
@import "components/quality-group";
@import "components/quality-item";
@import "components/quality-picker";
@import "components/quality-requirement";
@import "components/quality-update";
@import "components/second-chance";
@import "components/shop";
@import "components/sidebar";
@import "components/sidebar-quality";
/* */
@import "components/signup";
@import "components/signup-avatars";
@import "components/small-card";
@import "components/small-cards";
@import "components/snippet";
@import "components/storylet";
/* */
@import "components/storylet-root";
@import "components/storylets";
@import "components/they-say";
@import "components/tab-content";
@import "components/tooltip";
@import "components/top-stripe";
@import "components/travel-button";
@import "components/welcome";

@import "layout/grid.scss";
@import "layout/nav-content";
@import "layout/sidemenu";
@import "layout/structure";

/* */

// Hacky overrides for third-party inserted components
@import "components/braintree-overrides";

.ie11banner {
  display: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ie11banner {
    background: red;
    color: white;
    display: block;
    padding: 1rem;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
}
