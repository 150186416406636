.buttons {
  align-items: center;
  clear: both;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  text-align: right;

  @media (min-width: $screen-sm-min) {
    clear: none;
    width: 100%;
  }

  &::after {
    content: '';
    flex: 10;
  }
}

.buttons.buttons--no-squash {
  &::after {
    content: none;
  }
}

.buttons.buttons--left {
    flex-direction: row;
    justify-content: flex-start;
}

.buttons.buttons--space-between {
    justify-content: space-between;
}

.buttons--storylet-exit-options {
  margin-top: 1rem;
}
