.inner-tabs {
  border-bottom: solid 2px $brown-light;
  display: flex;
  flex-wrap: wrap; // For *very* small screens
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.inner-tab {
  align-items: baseline;
  background-color: transparent;
  color: $blue-darker;
  cursor: pointer;
  display: flex;
  flex: 1;
  padding: 0;
  transition: color 200ms;

  &:focus {
    .inner-tab__label {
      border-radius: 1px;
      outline: $blue-darker solid 2px;
      outline-offset: 2px;
    }
  }

  .inner-tab__icon {
    background-color: $blue-darker;
    transition: background-color 200ms;
  }

  &.notifying {
    animation: glow 0.5s infinite alternate;
  }
}

.inner-tab--active {
  color: $brown-dark;

  &:focus {
    .inner-tab__label {
      outline-color: $brown-dark;
    }
  }

  .inner-tab__icon {
    background-color: $brown-dark;
  }
}

.inner-tab--fate {
  padding-left: 0.5rem;

  @media(max-width: $screen-sm-down) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0;
  }
}

.inner-tab--with-border {
  border-right: 2px solid $brown-light;
  margin-right: 2%;
}

.inner-tab--with-border--fate {
  margin-right: 0;
}

.inner-tab--no-icon {
  align-items: flex-end;
  min-height: 32px;
}

.inner-tab__icon {
  margin-right: 12px;
}

.inner-tab__icon--fate {
  @media (max-width: $screen-sm-down) {
    margin-right: 0;
  }
}

.inner-tab__icon--star {
  position: relative;
  top: -2px;
}

.inner-tab__label {
  bottom: 7px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
}

.inner-tab__label--fate {
  @media(max-width: $screen-sm-down) {
    margin-top: 12px;
    text-align: center;
  }
}

.inner-tabs-2 {
  @extend .inner-tabs;

  display: grid;
  grid-template-columns: 1fr 1fr;

  .inner-tab {
    padding-left: 0.4rem;
  }
}

.inner-tabs-3 {
  @extend .inner-tabs-2;

  grid-template-columns: auto auto auto;

  .inner-tab {
    @media (max-width: $screen-xs-down) {
      padding-left: 0;
      justify-content: center;
    }
  }

  .inner-tab__icon {
    @media (max-width: $screen-sm-up) {
      margin-right: 12px;
    }

    @media (max-width: ($screen-sm-up - 10px)) {
      margin-right: 11px;
    }

    @media (max-width: ($screen-sm-up - 20px)) {
      margin-right: 10px;
    }

    @media (max-width: ($screen-sm-up - 30px)) {
      margin-right: 9px;
    }

    @media (max-width: ($screen-sm-up - 40px)) {
      margin-right: 8px;
    }

    @media (max-width: ($screen-sm-up - 50px)) {
      margin-right: 7px;
    }

    @media (max-width: ($screen-sm-up - 60px)) {
      margin-right: 6px;
    }

    @media (max-width: ($screen-sm-up - 70px)) {
      margin-right: 5px;
    }

    @media (max-width: ($screen-sm-up - 80px)) {
      margin-right: 4px;
    }

    @media (max-width: ($screen-sm-up - 90px)) {
      margin-right: 3px;
    }

    @media (max-width: ($screen-sm-up - 100px)) {
      margin-right: 2px;
    }
  }
}
