.myself__display-qualities {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1rem 0.5rem;

  @media (min-width: $screen-smd-up) {
    flex-direction: row;
  }
}

.myself__display-quality {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: $font-family-serif;
  padding: 0 0.5rem;
}

.myself-profile--medium {
  display: flex;
  justify-content: space-around;
}

.myself-profile__button--change-face {
  margin-left: 0;
  margin-right: 16px;
}

.myself-profile__cameo-and-button {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media (min-width: $screen-phone-portrait-up) {
    margin-bottom: 0;
  }
}

.myself-profile__cameo-and-lodgings {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media (min-width: $screen-phone-portrait-up) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.myself-profile__lodgings-container {
  @media (min-width: 1300px) {
    margin-left: 1rem;
  }
}

.myself-profile__cameo-border,
.myself-profile__lodgings-border {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-right: 16px;
  padding: 11px 10px 10px 11px;

  @media (min-width: $screen-md-up) {
    padding: 11px;
  }

  @media (min-width: $screen-xl-up) {
    padding: 14px 13px 13px;
  }

  & > div {
    position: relative;
  }

  & > div:after {
    bottom: 0;
    border-radius: 2px;
    box-shadow: inset 0 0 4px #000;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.myself-profile__cameo,
.myself-profile__lodgings {
  display: block;
  width: calc(148px - 24px);

  @media (min-width: $screen-md-up) {
    width: 165px;
  }

  @media (min-width: $screen-lg-up) {
    height: auto;
    width: calc(148px - 24px);
  }

  @media (min-width: $screen-xl-up) {
    width: 165px;
  }
}

.myself-profile__header {
  margin-bottom: 0.5rem;
  text-align: center;
  width: 100%;

  @media (min-width: $screen-phone-portrait-up) {
    text-align: left;
  }
}

.myself-profile__panel {
  @include extra-border(before, (3px, 3px, 3px, 3px), 1px, $brown-light);

  background-color: $brown-lightest;
  background-position: bottom left;
  background-repeat: no-repeat;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0;

  @media (min-width: $screen-smd-up) {
    align-self: stretch;
    flex: 0.8;
  }
}

.myself-profile__view-and-set-private {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 0.5rem;

  @media (min-width: $screen-lg-up) {
    align-items: flex-end;
    flex-direction: column-reverse;
  }

  @media (min-width: $screen-xl-up) {
    align-items: flex-end;
    flex-direction: row-reverse;
  }

  @media (min-width: 1300px) {
    flex-direction: column-reverse;
  }
}
